// @ts-nocheck
import React, { forwardRef } from 'react';
import type { ButtonProps, ColorTokens, TamaguiElement, TextProps } from 'tamagui';
import { Button as TamaguiButton, VisuallyHidden, XStack } from 'tamagui';
import { skeleton } from '../../skeleton';
import type { IconName } from '../Icon/Icon';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';

type Variant = 'primary' | 'secondary' | 'tertiary' | 'quartenary' | 'warning' | 'error' | 'naked';
type ButtonType = React.ButtonHTMLAttributes<HTMLButtonElement>['type'];

type Size = 'sm' | 'md' | 'lg';
type BaseProps = {
  size?: Size;
  variant?: Variant;
  loading?: boolean;
  type?: ButtonType;
  icon?: IconName;
  iconSize?: number;
  children: React.ReactNode;
  iconColor?: ColorTokens;
  label?: never;
  fontWeight?: TextProps['fontWeight'];
  textProps?: TextProps;
  title?: string;
};

// If no children are passed, then label and icon are required
type BasePropsWithoutChildren = Omit<BaseProps, 'children' | 'label' | 'icon'> & {
  children?: never;
  label: string;
  icon: IconName;
  iconSize?: number;
};

// All types on TamaguiButton except for the ones in BaseProps and fontWeight (since that one is used on the <Text /> component)
type RestOfButtonProps = Omit<ButtonProps, keyof BaseProps | 'fontWeight' | 'iconAfter'>;

export type Props = (BaseProps | BasePropsWithoutChildren) & RestOfButtonProps;

const variantProps: {
  [key in Variant]: React.ComponentProps<typeof TamaguiButton>;
} = {
  primary: {
    backgroundColor: '$contrast',
    color: '$base',
    borderColor: '$transparent',
    borderWidth: 2,
    hoverStyle: {
      backgroundColor: '$contrastHover',
    },
    pressStyle: {
      backgroundColor: '$contrast',
    },
  },
  secondary: {
    backgroundColor: '$base',
    color: '$contrast',
    borderColor: '$contrast',
    borderWidth: 2,
    hoverStyle: {
      backgroundColor: '$baseHover',
    },
    pressStyle: {
      backgroundColor: '$base',
    },
  },
  tertiary: {
    backgroundColor: '$subtlePale',
    color: '$contrast',
    borderColor: '$subtlePale',
    borderWidth: 2,
    hoverStyle: {
      backgroundColor: '$subtlePaleHover',
      borderColor: '$subtlePaleHover',
    },
    pressStyle: {
      backgroundColor: '$subtlePale',
      borderColor: '$subtlePale',
    },
  },
  quartenary: {
    backgroundColor: '$base',
    color: '$contrast',
    borderColor: '$border',
    borderWidth: 1,
    hoverStyle: {
      backgroundColor: '$baseHover',
      borderColor: '$border',
    },
    pressStyle: {
      backgroundColor: '$base',
      borderColor: '$border',
    },
  },
  warning: {
    backgroundColor: '$base',
    color: '$warning',
    borderColor: '$warning',
    borderWidth: 2,
    hoverStyle: {
      backgroundColor: '$subtlePaleHover',
      borderColor: '$warning',
    },
    pressStyle: {
      backgroundColor: '$base',
    },
  },
  error: {
    backgroundColor: '$base',
    color: '$error',
    borderColor: '$error',
    borderWidth: 2,
    hoverStyle: {
      backgroundColor: '$subtlePaleHover',
      borderColor: '$error',
    },
    pressStyle: {
      backgroundColor: '$base',
    },
  },
  naked: {
    px: 0,
    minWidth: 'auto',
    height: 'auto',
    backgroundColor: 'transparent',
    color: 'transparent',
    borderWidth: 0,
    hoverStyle: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
    pressStyle: {
      backgroundColor: 'transparent',
    },
  },
};

const sizeProps: {
  [key in Size]: React.ComponentProps<typeof TamaguiButton>;
} = {
  sm: {
    height: '$8',
    fontSize: '$12',
  },
  md: {
    height: '$10',
    fontSize: '$14',
  },
  lg: {
    height: '$12',
    fontSize: '$14',
    paddingHorizontal: '$8',
  },
};

const getIconColor = (variant: Variant): ColorTokens => {
  switch (variant) {
    case 'primary':
      return '$base';

    case 'secondary':
      return '$contrast';

    case 'warning':
      return '$warning';

    case 'error':
      return '$error';

    default:
      return '$contrast';
  }
};

const sizeWithoutChildrenProps: {
  [key in Size]: React.ComponentProps<typeof TamaguiButton>;
} = {
  sm: {
    padding: 0,
    width: '$6',
    height: '$6',
  },
  md: {
    padding: 0,
    width: '$8',
    height: '$8',
  },
  lg: {
    padding: 0,
    width: '$10',
    height: '$10',
  },
};

const iconSizeWithoutChildrenProps: {
  [key in Size]: number;
} = {
  sm: 12,
  md: 14,
  lg: 18,
};

const Button = forwardRef<TamaguiElement, Props>(
  (
    {
      children,
      label,
      variant = 'primary',
      size = 'md',
      type = 'button',
      icon,
      iconSize,
      fontWeight = '$medium',
      textProps = {},
      disabled,
      iconColor,
      loading,
      ...restProps
    },
    ref,
  ) => {
    return (
      <TamaguiButton
        borderWidth={0}
        borderRadius={children ? 10000 : 9999}
        minWidth={children ? '$20' : undefined}
        className={skeleton.className('box', loading)}
        px="$4"
        // @ts-ignore
        type={type}
        {...sizeProps[size]}
        {...(!children ? { ...sizeWithoutChildrenProps[size] } : {})}
        {...variantProps[variant]}
        disabled={disabled}
        pointerEvents={disabled ? 'none' : 'auto'}
        focusStyle={{
          // @ts-ignore
          backgorundColor: variantProps[variant].backgroundColor,
          outlineWidth: 0,
        }}
        {...(disabled
          ? {
              opacity: 0.6,
              hoverStyle: {
                backgroundColor: variantProps[variant].backgroundColor,
                borderColor: variantProps[variant].borderColor,
              },
            }
          : {})}
        {...restProps}
        cursor={disabled ? 'not-allowed' : 'pointer'}
        ref={ref}
      >
        {children ? (
          <Text
            color={variantProps[variant].color}
            fontSize={sizeProps[size].fontSize}
            fontWeight={fontWeight}
            cursor={disabled ? 'not-allowed' : 'pointer'}
            numberOfLines={1}
            overflow="hidden"
            {...textProps}
          >
            {children}
          </Text>
        ) : (
          <VisuallyHidden>
            <Text>{label}</Text>
          </VisuallyHidden>
        )}
        {icon && (
          <XStack ml={children ? '$2' : undefined} cursor={disabled ? 'not-allowed' : 'pointer'}>
            <Icon
              icon={icon}
              color={iconColor || getIconColor(variant)}
              size={iconSize ?? iconSizeWithoutChildrenProps[size]}
            />
          </XStack>
        )}
      </TamaguiButton>
    );
  },
);

export default Button;
