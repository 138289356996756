import { setContext } from '@apollo/client/link/context';
import { getAccessToken } from '@holo/jwt';
import type { GetServerSidePropsContext } from 'next';

export const createAuthLink = (context?: GetServerSidePropsContext) => {
  return setContext((_, { headers }) => {
    const token = getAccessToken(context);

    return {
      headers: {
        ...headers,
        ...(token && { authorization: `Bearer ${token}` }),
      },
    };
  });
};
