import { COOKIE_NAME } from '@holo/constants';
import { getCookie, setCookie } from 'cookies-next';

export type UserType = 'producer' | 'customer';
export type CustomerType = 'wholesale' | 'retail';

export type User = {
  id: string;
  role: string;
  type: UserType;
  level: CustomerType;
  token: string;
};

export const getUserFromToken = (token: string | null | undefined): User | null => {
  if (!token) {
    return null;
  }

  try {
    const payload = token.split('.')[1];
    // @ts-ignore
    const { uid: id, role, type, level } = JSON.parse(atob(payload));

    return {
      id,
      role,
      type,
      token,
      level,
    };
  } catch (_err) {
    return null;
  }
};

export const getAccessToken = (context?: any) => {
  return getCookie(COOKIE_NAME.ACCESS_TOKEN, context) ?? null;
};

export const getRefreshToken = (context?: any) => {
  return getCookie(COOKIE_NAME.REFRESH_TOKEN, context) ?? null;
};

export const setRefreshToken = (value: string, context?: any) => {
  setCookie(COOKIE_NAME.REFRESH_TOKEN, value, context);
};

export const setAccessToken = (value: string, context?: any) => {
  setCookie(COOKIE_NAME.ACCESS_TOKEN, value, context);
};
