import Drawers from '@/components/Drawers';
import Footer from '@/components/Footer';
import Modals from '@/components/Modals';
import { YStack } from '@holo/ui';
import dynamic from 'next/dynamic';

const MobileHeader = dynamic(() => import('@/components/Header/HeaderMobile'));
const DesktopHeader = dynamic(() => import('@/components/Header/HeaderDesktop'));

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  return (
    <YStack minHeight="100vh">
      <Drawers />
      <Modals />
      <MobileHeader />
      <DesktopHeader />
      <YStack flex={1} tag="main" mb="$20">
        {children}
      </YStack>
      <Footer />
    </YStack>
  );
};

export default Layout;
