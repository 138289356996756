import type { Direction } from '@/components/Drawer';
import Drawer from '@/components/Drawer';
import { drawersVar } from '@/store';
import type { DrawerId } from '@/store';
import { useReactiveVar } from '@holo/client';
import { Logo } from '@holo/ui/assets';
import dynamic from 'next/dynamic';

type DrawerConfig = {
  id: DrawerId;
  direction?: Direction;
  Component: React.ComponentType;
  header: string | React.ReactNode;
};

export const drawers: DrawerConfig[] = [
  {
    id: 'cart',
    Component: dynamic(() => import('./CartDrawer'), { ssr: false }),
    header: 'Cart',
  },
  {
    id: 'active-carts',
    Component: dynamic(() => import('./ActiveCartsDrawer'), { ssr: false }),
    header: 'Carts',
  },
  {
    id: 'mobile-menu',
    direction: 'from-left',
    Component: dynamic(() => import('./MobileMenuDrawer'), { ssr: false }),
    header: <Logo />,
  },
];

const Drawers = () => {
  const { currentId } = useReactiveVar(drawersVar);

  const drawer = drawers.find((d) => d.id === currentId);
  const Component = drawer?.Component;

  return (
    <Drawer id={drawer?.id} direction={drawer?.direction} header={drawer?.header}>
      {Component && <Component />}
    </Drawer>
  );
};

export default Drawers;
