import type { DrawerId } from '@/store';
import { drawersVar } from '@/store';
import { useReactiveVar } from '@holo/client';

export const useDrawer = (id?: DrawerId) => {
  const drawers = useReactiveVar(drawersVar);

  const open = () => {
    if (id) {
      drawersVar({
        currentId: id,
        ids: [...drawers.ids, id],
      });
    }
  };

  const close = () => {
    drawersVar({
      currentId: undefined,
      ids: drawers.ids.filter((d) => d !== id),
    });
  };

  const closeOther = () => {
    if (id) {
      drawersVar({
        currentId: id,
        ids: [id],
      });
    }
  };

  return {
    id,
    isOpen: id && drawers.ids.includes(id),
    open,
    close,
    closeOther,
  };
};
