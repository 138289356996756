export const CDN_ENDPOINT = 'https://cdn.holo.ag';

export const COOKIE_NAME = {
  ACCESS_TOKEN: 'access-token',
  REFRESH_TOKEN: 'refresh-token',
  TYPE: 'type',
  CART_ID: 'cart-id',
};

export const ERROR_CODES = {
  AUTHENTICATION_REQUIRED: 'AUTHENTICATION_REQUIRED',
  ACCESS_TOKEN_EXPIRED: 'ACCESS_TOKEN_EXPIRED',
  DELIVERY_NOT_AVAILABLE: 'DELIVERY_NOT_AVAILABLE',
};

export const USER_TYPE = {
  wholesale: 'wholesale',
  retail: 'retail',
};

export const ADDRESS_TYPE = {
  shipping: 'shipping',
  billing: 'billing',
};

export const KEYBOARD_KEYS = {
  BACKSPACE: 'Backspace',
  ESC: 'Escape',
  ENTER: 'Enter',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
};
