import '@holo/ui/css/reset.css';
import '@holo/ui/css/skeleton.css';
import './app.css';

import DefaultLayout from '@/layouts/DefaultLayout';
import { ApolloProvider, setupApollo } from '@holo/client';
import { DesignProvider, config } from '@holo/ui/core';
import { NextThemeProvider } from '@tamagui/next-theme';
import { Analytics } from '@vercel/analytics/react';
import type { NextPage } from 'next';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { Inter } from 'next/font/google';
import Router from 'next/router';
import type { ScriptProps } from 'next/script';
import NProgress from 'nprogress';
import { Suspense } from 'react';

const Toaster = dynamic(() => import('sonner').then((mod) => mod.Toaster), {
  ssr: true,
});

// Page and Props is a way to extend Component with a Layout property
type Page<P = any> = NextPage<P> & {
  Layout: (page: ScriptProps) => JSX.Element;
};

type AppPropsWithLayout = AppProps & {
  Component: Page;
};

NProgress.configure({ showSpinner: false });

Router.events.on('routeChangeStart', (_, { shallow }) => {
  if (!shallow) {
    NProgress.start();
  }
});
Router.events.on('routeChangeError', () => NProgress.done());
Router.events.on('routeChangeComplete', () => {
  NProgress.done();
});

const inter = Inter({
  subsets: ['latin'],
});

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const client = setupApollo(pageProps);

  const Layout = Component.Layout || DefaultLayout;

  return (
    <ApolloProvider client={client}>
      {/* NOTE: drawers and modals needs this for some reason otherwise we rely on variable fonts */}
      {/* eslint-disable-next-line */}
      <style jsx global>{`
        :root {
          --font-inter: ${inter.style.fontFamily};
        }
      `}</style>
      <NextThemeProvider forcedTheme="light" skipNextHead>
        <DesignProvider config={config} disableInjectCSS>
          <Layout>
            <Suspense fallback={null}>
              <Toaster position="bottom-right" />
              <Component {...pageProps} />
              <Analytics debug={false} />
            </Suspense>
          </Layout>
        </DesignProvider>
      </NextThemeProvider>
    </ApolloProvider>
  );
};

export default appWithTranslation(App);
