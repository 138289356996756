type DynamicData = {
  [key: string]: any;
};

type SkeletonName = 'circle' | 'line' | 'box';

const SKELETON_CLASS_NAME_PREFIX = 'skeleton';

export const generateItems = (n = 1): DynamicData[] =>
  [...Array(n)].map((_, i) => ({ id: i.toString(), node: { id: i.toString() } }));
const className = (name: SkeletonName, loading?: boolean) => (loading ? `${SKELETON_CLASS_NAME_PREFIX}-${name}` : '');

export const skeleton = {
  generateItems,
  className,
};
