import React from 'react';
import NextLink from 'next/link';
import type NextLinkType from 'next/link';
import type { LinkProps } from 'next/link';

export const Link = React.forwardRef((props: LinkProps, ref) => {
  // NOTE: (prefetch) https://www.charlievuong.com/how-the-next-js-link-component-can-affect-your-website-speed/
  // @ts-ignore (ref)
  return <NextLink prefetch={false} ref={ref} {...props} />;
}) as any as typeof NextLinkType;
