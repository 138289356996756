import { modalsVar } from '@/store';
import type { ModalId } from '@/store';
import { useReactiveVar } from '@holo/client';
import dynamic from 'next/dynamic';

type ModalConfig = {
  id: ModalId;
  Component: React.ComponentType;
};

export const modals: ModalConfig[] = [
  {
    id: 'add-payment-method',
    Component: dynamic(() => import('./AddPaymentMethodModal'), { ssr: false }),
  },
  {
    id: 'add-shipping-address',
    Component: dynamic(() => import('./AddShippingAddressModal'), { ssr: false }),
  },
  {
    id: 'add-billing-address',
    Component: dynamic(() => import('./AddBillingAddressModal'), { ssr: false }),
  },
  {
    id: 'pay-order',
    Component: dynamic(() => import('./PayOrderModal'), { ssr: false }),
  },
  {
    id: 'reorder',
    Component: dynamic(() => import('./ReorderModal'), { ssr: false }),
  },
  {
    id: 'add-team-member',
    Component: dynamic(() => import('./AddTeamMemberModal'), { ssr: false }),
  },
  {
    id: 'edit-team-member',
    Component: dynamic(() => import('./EditTeamMemberModal'), { ssr: false }),
  },
  {
    id: 'delete-team-member',
    Component: dynamic(() => import('./DeleteTeamMemberModal'), { ssr: false }),
  },
  {
    id: 'join-pilot-program',
    Component: dynamic(() => import('./JoinPilotProgramModal'), { ssr: false }),
  },
];

const Modals = () => {
  const { currentId } = useReactiveVar(modalsVar);

  const modal = modals.find((m) => m.id === currentId);
  const Component = modal?.Component;

  if (!Component) {
    return null;
  }

  return <Component />;
};

export default Modals;
