import { createAnimations } from '@tamagui/animations-css';
import { shorthands } from '@tamagui/shorthands';
import { createFont, createTamagui, createTheme, createTokens } from 'tamagui';
import tinycolor from 'tinycolor2';

const animations = createAnimations({
  ultra: 'ease-in 100ms',
  fast: 'ease-in 150ms',
  medium: 'ease-in 300ms',
  slow: 'ease-in 450ms',
});

const interFont = createFont({
  family: 'var(--font-inter)',
  size: {
    true: 12,
    4: 16,
    9: 9,
    10: 10,
    11: 11,
    12: 12,
    13: 13,
    14: 14,
    15: 15,
    16: 16,
    18: 18,
    20: 20,
    24: 24,
    32: 32,
    40: 40,
    42: 42,
    52: 52,
    72: 72,
  },
  lineHeight: {
    true: 24,
    1: 17,
    2: 22,
    3: 25,
  },
  weight: {
    regular: '400',
    medium: '500',
    'semi-bold': '600',
  },
  letterSpacing: {
    true: 0,
    0: 0,
    1: 1,
  },
});

const createHoverVariant = (color: tinycolor.Instance) => {
  if (color.toName() === 'transparent') {
    return tinycolor('transparent');
  }

  if (color.toName() === 'white') {
    return tinycolor('#f2f2f2');
  }

  if (color.toName() === 'black') {
    return tinycolor('#333');
  }

  if (color.isDark()) {
    return color.lighten(4);
  }

  return color.darken(4);
};

const lightBaseColors = {
  base: tinycolor('#fff'),
  basePale: tinycolor('#b2b2b2'),
  contrast: tinycolor('#000'),
  contrastPale: tinycolor('#4d4d4d'),
  // NOTE: Can't have name accentDeepPale (bug?)
  accentDP: tinycolor('#D0E4D4'),
  accent: tinycolor('#2d6936'),
  accentPale: tinycolor('#e8f1ea'),
  darkGray: tinycolor('#6e6f71'),
  accentDeep: tinycolor('#3d904a'),
  border: tinycolor('#dddfe1'),
  subtlePale: tinycolor('#f0f1f3'),
  error: tinycolor('#c03636'),
  errorPale: tinycolor('#fadcdc'),
  warning: tinycolor('#b5801a'),
  warningPale: tinycolor('#faeedc'),
};

const darkBaseColors = {
  base: tinycolor('#000000'),
  basePale: tinycolor('#000000'),
  contrast: tinycolor('#fff'),
  contrastPale: tinycolor('#fff'),
  accentDP: tinycolor('#111E12'),
  accent: tinycolor('#85B083'),
  accentPale: tinycolor('#0B120B'),
  darkGray: tinycolor('#6e6f71'),
  accentDeep: tinycolor('#85B083'),
  border: tinycolor('#1F2123'),
  subtlePale: tinycolor('#0d0e10'),
  error: tinycolor('#FF8F8A'),
  errorPale: tinycolor('#301818'),
  warning: tinycolor('#A17627'),
  warningPale: tinycolor('#190F00'),
};

export const colors = {
  transparent: 'transparent',

  base: lightBaseColors.base.toHexString(),
  baseHover: createHoverVariant(lightBaseColors.base).toHexString(),

  basePale: lightBaseColors.basePale.toHexString(),
  basePaleHover: createHoverVariant(lightBaseColors.basePale).toHexString(),

  contrast: lightBaseColors.contrast.toHexString(),
  contrastHover: createHoverVariant(lightBaseColors.contrast).toHexString(),

  contrastPale: lightBaseColors.contrastPale.toHexString(),
  contrastPaleHover: createHoverVariant(lightBaseColors.contrastPale).toHexString(),

  accentDP: lightBaseColors.accentDP.toHexString(),

  accent: lightBaseColors.accent.toHexString(),
  accentHover: createHoverVariant(lightBaseColors.accent).toHexString(),

  accentPale: lightBaseColors.accentPale.toHexString(),
  accentPaleHover: createHoverVariant(lightBaseColors.accentPale).toHexString(),

  accentDeep: lightBaseColors.accentDeep.toHexString(),
  accentDeepHover: createHoverVariant(lightBaseColors.accentDeep).toHexString(),

  darkGray: lightBaseColors.darkGray.toHexString(),
  darkGrayHover: createHoverVariant(lightBaseColors.darkGray).toHexString(),

  border: lightBaseColors.border.toHexString(),
  borderHover: createHoverVariant(lightBaseColors.border).toHexString(),

  subtlePale: lightBaseColors.subtlePale.toHexString(),
  subtlePaleHover: createHoverVariant(lightBaseColors.subtlePale).toHexString(),

  error: lightBaseColors.error.toHexString(),
  errorHover: createHoverVariant(lightBaseColors.error).toHexString(),

  errorPale: lightBaseColors.errorPale.toHexString(),
  errorPaleHover: createHoverVariant(lightBaseColors.errorPale).toHexString(),

  warning: lightBaseColors.warning.toHexString(),
  warningHover: createHoverVariant(lightBaseColors.warning).toHexString(),

  warningPale: lightBaseColors.warningPale.toHexString(),
  warningPaleHover: createHoverVariant(lightBaseColors.warningPale).toHexString(),

  // Dark
  darkBase: darkBaseColors.base.toHexString(),
  darkBaseHover: createHoverVariant(darkBaseColors.base).toHexString(),

  darkBasePale: darkBaseColors.basePale.toHexString(),
  darkBasePaleHover: createHoverVariant(darkBaseColors.basePale).toHexString(),

  darkContrast: darkBaseColors.contrast.toHexString(),
  darkContrastHover: createHoverVariant(darkBaseColors.contrast).toHexString(),

  darkContrastPale: darkBaseColors.contrastPale.toHexString(),
  darkContrastPaleHover: createHoverVariant(darkBaseColors.contrastPale).toHexString(),

  darkaccentDP: darkBaseColors.accentDP.toHexString(),

  darkAccent: darkBaseColors.accent.toHexString(),
  darkAccentHover: createHoverVariant(darkBaseColors.accent).toHexString(),

  darkAccentPale: darkBaseColors.accentPale.toHexString(),
  darkAccentPaleHover: createHoverVariant(darkBaseColors.accentPale).toHexString(),

  darkAccentDeep: darkBaseColors.accentDeep.toHexString(),
  darkAccentDeepHover: createHoverVariant(darkBaseColors.accentDeep).toHexString(),

  darkDarkGray: darkBaseColors.darkGray.toHexString(),
  darkDarkGrayHover: createHoverVariant(darkBaseColors.darkGray).toHexString(),

  darkBorder: darkBaseColors.border.toHexString(),
  darkBorderHover: createHoverVariant(darkBaseColors.border).toHexString(),

  darkSubtlePale: darkBaseColors.subtlePale.toHexString(),
  darkSubtlePaleHover: createHoverVariant(darkBaseColors.subtlePale).toHexString(),

  darkError: darkBaseColors.error.toHexString(),
  darkErrorHover: createHoverVariant(darkBaseColors.error).toHexString(),

  darkErrorPale: darkBaseColors.errorPale.toHexString(),
  darkErrorPaleHover: createHoverVariant(darkBaseColors.errorPale).toHexString(),

  darkWarning: darkBaseColors.warning.toHexString(),
  darkWarningHover: createHoverVariant(darkBaseColors.warning).toHexString(),

  darkWarningPale: darkBaseColors.warningPale.toHexString(),
  darkWarningPaleHover: createHoverVariant(darkBaseColors.warningPale).toHexString(),
};

const light = createTheme({
  // Tamagui required colors
  color: colors.contrast,
  backgroundStrong: 'inherit',
  backgroundPress: 'inherit',
  backgroundFocus: 'inherit',
  backgroundHover: 'inherit',
  borderColorHover: 'inherit',
  borderColorFocus: 'transparent',
  borderColorPress: 'inherit',
  colorPress: 'inherit',
  placeholderColor: colors.border,
  borderColor: colors.border,

  // Our colors
  background: colors.base,
  backgroundInverse: '#000000',
  base: colors.base,
  contrast: colors.contrast,
  accentDP: colors.accentDP,
  accent: colors.accent,
  accentPale: colors.accentPale,
  accentDeep: colors.accentDeep,
  darkGray: colors.darkGray,
  border: colors.border,
  subtlePale: colors.subtlePale,
  error: colors.error,
  errorPale: colors.errorPale,
  warning: colors.warning,
  warningPale: colors.warningPale,
  baseHover: colors.baseHover,
});

const dark = createTheme({
  // Tamagui required colors
  color: colors.darkContrast,
  backgroundStrong: 'inherit',
  backgroundPress: 'inherit',
  backgroundFocus: 'inherit',
  backgroundHover: 'inherit',
  borderColorHover: 'inherit',
  borderColorFocus: 'inherit',
  borderColorPress: 'inherit',
  colorPress: 'inherit',
  placeholderColor: colors.darkBorder,
  borderColor: colors.darkBorder,

  // Our colors
  background: colors.darkBase,
  backgroundInverse: '#fff',
  base: colors.darkBase,
  contrast: colors.darkContrast,
  accentDP: colors.darkaccentDP,
  accent: colors.darkAccent,
  accentPale: colors.darkAccentPale,
  accentDeep: colors.darkAccentDeep,
  darkGray: colors.darkDarkGray,
  border: colors.darkBorder,
  subtlePale: colors.darkSubtlePale,
  error: colors.darkError,
  errorPale: colors.darkErrorPale,
  warning: colors.darkWarning,
  warningPale: colors.darkWarningPale,
  baseHover: colors.darkBaseHover,
});

const media = {
  xxs: { minWidth: 390 },
  xs: { minWidth: 660 },
  sm: { minWidth: 800 },
  md: { minWidth: 1020 },
  lg: { minWidth: 1280 },
  xl: { minWidth: 1355 },
  containerPadding: { minWidth: 1360 + 32 },
};

export const zIndex = {
  0: 0,
  1: 100,
  2: 200,
  3: 300,
  4: 400,
  5: 500,
};

export const size = {
  true: 16,
  0: 0,
  0.5: 2,
  1: 4,
  1.5: 6,
  2: 8,
  2.5: 10,
  3: 12,
  4: 16,
  5: 20,
  6: 24,
  7: 28,
  8: 32,
  9: 36,
  10: 40,
  11: 44,
  12: 48,
  13: 52,
  14: 56,
  15: 60,
  16: 64,
  17: 68,
  18: 72,
  19: 76,
  20: 80,
  25: 100,
  30: 120,
  40: 160,
  4000: 4000,
};

export const space = {
  ...size,
  '-0': -0,
  '-0.5': -2,
  '-1': -4,
  '-1.5': -6,
  '-2': -8,
  '-2.5': -10,
  '-3': -12,
  '-4': -16,
  '-5': -20,
  '-6': -24,
  '-7': -28,
  '-8': -32,
  '-9': -36,
  '-10': -40,
};

export const tokens = createTokens({
  color: colors,
  radius: size,
  zIndex,
  space,
  size,
});

const themes = {
  light,
  dark,
};

const fonts = {
  heading: interFont,
  display: interFont,
  body: interFont,
};

const config = createTamagui({
  animations,
  shouldAddPrefersColorThemes: true,
  themeClassNameOnRoot: true,
  fonts,
  themes,
  tokens,
  shorthands,
  media,
});

export type Config = typeof config;

declare module 'tamagui' {
  interface TamaguiCustomConfig extends Config {}
}

export default config;
