import { makeVar } from '@holo/client';

// Add available drawers to this list
const AVAILABLE_DRAWERS = ['cart', 'active-carts', 'mobile-menu'] as const;

// Replace `any` with the type you want the modal to take
namespace ModalsData {
  export type AddPaymentMethod = any;

  export type AddShippingAddress = {
    onCompleted?: (addressId: string) => void;
  };

  export type AddBillingAddress = {
    onCompleted?: (addressId: string) => void;
  };

  export type PayOrder = any;

  export type Reorder = any;

  export type AddTeamMember = any;

  export type DeleteTeamMember = {
    id: string;
  };

  export type EditTeamMember = {
    id: string;
  };

  export type JoinPilotProgram = any;
}

export type ModalData<Id extends ModalId> = Id extends 'add-payment-method'
  ? ModalsData.AddPaymentMethod
  : Id extends 'add-shipping-address'
  ? ModalsData.AddShippingAddress
  : Id extends 'add-billing-address'
  ? ModalsData.AddBillingAddress
  : Id extends 'pay-order'
  ? ModalsData.PayOrder
  : Id extends 'reorder'
  ? ModalsData.Reorder
  : Id extends 'add-team-member'
  ? ModalsData.AddTeamMember
  : Id extends 'delete-team-member'
  ? ModalsData.DeleteTeamMember
  : Id extends 'edit-team-member'
  ? ModalsData.EditTeamMember
  : Id extends 'join-pilot-program'
  ? ModalsData.JoinPilotProgram
  : never;

const AVAILABLE_MODALS = [
  'add-payment-method',
  'add-shipping-address',
  'add-billing-address',
  'pay-order',
  'reorder',
  'add-team-member',
  'delete-team-member',
  'edit-team-member',
  'join-pilot-program',
] as const;

export type ModalId = typeof AVAILABLE_MODALS[number];

export type Modals = {
  currentId?: string;
  ids: ModalId[];
};

export const modalsVar = makeVar<Modals>({
  currentId: undefined,
  ids: [],
});

export const modalDataVar = makeVar<ModalData<ModalId>>({});

export type DrawerId = typeof AVAILABLE_DRAWERS[number];

export type Drawers = {
  currentId?: string;
  ids: DrawerId[];
};

export const drawersVar = makeVar<Drawers>({
  currentId: undefined,
  ids: [],
});

export const searchFilterValueVar = makeVar<string>('');
