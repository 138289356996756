import type { YStackProps } from '@holo/ui';
import { YStack } from '@holo/ui';

type Props = YStackProps;

const DrawerFooter = ({ children, ...restProps }: Props) => {
  return <YStack {...restProps}>{children}</YStack>;
};

export default DrawerFooter;
