import { Text as TamaguiText } from 'tamagui';
import type { TextProps } from 'tamagui';
import { skeleton } from '../../skeleton';

type Props = TextProps & { loading?: boolean };

const Text = ({ children, loading, fontSize = '$14', ...props }: Props) => (
  <TamaguiText
    fontFamily="$body"
    fontSize={fontSize}
    {...props}
    className={skeleton.className(props?.borderRadius ? 'circle' : 'line', loading)}
  >
    {children}
  </TamaguiText>
);

export default Text;
