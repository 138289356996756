import { Circle as TamaguiCircle } from 'tamagui';
import type { CircleProps } from 'tamagui';
import { skeleton } from '../../skeleton';

type Props = CircleProps & { loading?: boolean; children?: React.ReactNode };

const Circle = ({ children, loading, ...props }: Props) => (
  <TamaguiCircle {...props} className={skeleton.className('circle', loading)}>
    {children}
  </TamaguiCircle>
);

export default Circle;
