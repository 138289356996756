import { styled } from 'tamagui';
import YStack from '../YStack/YStack';

export default styled(YStack, {
  mx: 'auto',
  width: '100%',
  maxWidth: 1360,
  px: '$4',
  $containerPadding: {
    px: 0,
  },
});
