import React from 'react';

import {
  Activity,
  AlertCircle,
  AlertOctagon,
  AlertTriangle,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  Backpack,
  Bell,
  Check,
  CheckSquare,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronsLeft,
  ChevronsRight,
  ChevronsUpDown,
  ClipboardList,
  Clock,
  CreditCard,
  Crop,
  DollarSign,
  Edit,
  Edit2,
  ExternalLink,
  FileBarChart,
  FileText,
  Files,
  Filter,
  Grid,
  HelpCircle,
  Home,
  Info,
  Landmark,
  Layers,
  LayoutList,
  LineChart,
  List,
  LogOut,
  MapPin,
  Menu,
  Minus,
  Monitor,
  Moon,
  MoreHorizontal,
  MoreVertical,
  Package,
  Plus,
  RefreshCw,
  Search,
  ShoppingBag,
  ShoppingCart,
  Sparkles,
  Sprout,
  Sun,
  Ticket,
  Trash2,
  User,
  Users,
  X,
} from '@tamagui/lucide-icons';

export const iconComponents = {
  files: Files,
  sun: Sun,
  moon: Moon,
  monitor: Monitor,
  chevronsUpDown: ChevronsUpDown,
  crop: Crop,
  sprout: Sprout,
  package: Package,
  alertTriangle: AlertTriangle,
  checkSquare: CheckSquare,
  search: Search,
  grid: Grid,
  sparkles: Sparkles,
  list: List,
  lineChart: LineChart,
  layers: Layers,
  plus: Plus,
  info: Info,
  shoppingBag: ShoppingBag,
  shoppingCart: ShoppingCart,
  menu: Menu,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronDown: ChevronDown,
  chevronUp: ChevronUp,
  chevronsLeft: ChevronsLeft,
  chevronsRight: ChevronsRight,
  user: User,
  mapPin: MapPin,
  close: X,
  clock: Clock,
  creditCard: CreditCard,
  arrowLeft: ArrowLeft,
  dollarSign: DollarSign,
  check: Check,
  help: HelpCircle,
  minus: Minus,
  arrowRight: ArrowRight,
  bell: Bell,
  house: Home,
  users: Users,
  chartDocument: FileBarChart,
  ticket: Ticket,
  layoutList: LayoutList,
  backpack: Backpack,
  landmark: Landmark,
  pen: Edit2,
  edit: Edit,
  trash: Trash2,
  alert: AlertOctagon,
  alertCircle: AlertCircle,
  arrowUp: ArrowUp,
  clipboardList: ClipboardList,
  logOut: LogOut,
  moreHorizontal: MoreHorizontal,
  fileText: FileText,
  refreshCw: RefreshCw,
  externalLink: ExternalLink,
  moreVertical: MoreVertical,
  arrowDown: ArrowDown,
  filter: Filter,
  activity: Activity,
};

export type IconName = keyof typeof iconComponents;

export type Props = {
  icon: IconName;
  color?: string;
  size?: number;
};

const Icon = ({ icon, color = '$contrast', size = 24 }: Props) => {
  const C = iconComponents[icon];

  if (!C) {
    throw new Error(`Could not find icon: ${icon}`);
  }

  return <C color={color} size={size} />;
};

export default Icon;
