import type { YStackProps } from '@holo/ui';
import { YStack } from '@holo/ui';

type Props = YStackProps;

const DrawerContent = ({ children, ...restProps }: Props) => {
  return (
    <YStack
      // @ts-ignore
      overflow="auto"
      backgroundColor="$background"
      flex={1}
      {...restProps}
    >
      {children}
    </YStack>
  );
};

export default DrawerContent;
