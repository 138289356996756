import { useDrawer } from '@/hooks/useDrawer';
import { useDrawers } from '@/hooks/useDrawers';
import type { DrawerId } from '@/store';
import { Dialog } from '@headlessui/react';
import { AnimatePresence, type Variants, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { useEffect, useRef } from 'react';
import DrawerContent from './DrawerContent';
import DrawerFooter from './DrawerFooter';
import DrawerHeader from './DrawerHeader';

export type Direction = 'from-right' | 'from-left';

type Props = {
  children: any;
  header: string | React.ReactNode;
  id?: DrawerId;
  direction?: Direction;
};

const animationVariants: Variants = {
  initial: (dir: Direction) => ({
    x: dir === 'from-right' ? '100%' : '-100%',
  }),
  animate: (dir: Direction) => ({
    x: dir === 'from-right' ? 0 : 0,
    transition: { duration: 0.3, ease: 'easeOut' },
  }),
  exit: (dir: Direction) => ({
    x: dir === 'from-right' ? '100%' : '-100%',
    transition: { duration: 0.3, ease: 'easeOut' },
  }),
};

const Drawer = ({ children, id, direction = 'from-right', header }: Props) => {
  const router = useRouter();
  const closeButtonRef = useRef(null);
  const { drawers, closeLast, closeAll } = useDrawers();
  const drawer = useDrawer(id);

  useEffect(() => {
    router.events.on('routeChangeComplete', closeAll);

    return () => {
      router.events.off('routeChangeComplete', closeAll);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && drawers.ids.length > 0) {
        closeLast();
      }
    };

    if (drawer?.isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    }
  }, [drawer?.isOpen]);

  return (
    <AnimatePresence>
      {drawer?.isOpen && (
        <Dialog static open={drawer.isOpen} onClose={() => {}} initialFocus={closeButtonRef}>
          <motion.div
            onClick={closeLast}
            className="drawer-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
          />

          <Dialog.Panel
            // @ts-ignore
            as={motion.div}
            initial="initial"
            animate="animate"
            exit="exit"
            custom={direction}
            variants={animationVariants}
            data-drawer-id={drawer.id}
            className={`drawer-content ${direction}`}
          >
            <DrawerHeader title={header} closeButtonRef={closeButtonRef} close={drawer.close} dismissType="close" />
            {children}
          </Dialog.Panel>
        </Dialog>
      )}
    </AnimatePresence>
  );
};

Drawer.Content = DrawerContent;
Drawer.Footer = DrawerFooter;

export default Drawer;
